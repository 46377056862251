import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";

const TeachingWise = () => {
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half fill-page">
      <TopComp />

      <p className="h1">The Employability Life Skills Programs</p>

      <p className="h2">
        How to get people to work effectively in an organisation
      </p>

      <img
        className="photo-full"
        src={require("../images/pictures/wagga.jpg")}
        alt=""
      />

      <div className="page-text">
        <p>Most organisations face most of these problems most of the time:</p>

        <ul>
          <li>Staff do not follow instructions</li>
          <li>Staff do not report accurately</li>
          <li>Staff bring problems instead of solutions</li>
          <li>
            There is too much opinion and not enough analysis based on evidence.
          </li>
        </ul>

        <p>This course addressess all these problems and includes:</p>

        <ul>
          <li>How to be a member of an organisation</li>
          <li>Integrity in reporting</li>
          <li>Communication with seniors, juniors and peers</li>
          <li>How to study training manuals</li>
        </ul>

        <p>
          It is suitable for any level of staff member, from the lowest fresher
          to the management team.
        </p>
        <p>Length: 1 week</p>
        <p>Method: Classroom with facilitator</p>
        <p>
          These programs were deployed in a country-wide project in India in
          partnership with Reliance Communications, Future Group and the
          Ministry of Rural Development. 45,000 students graduated these
          programs AND gained employment (or went on to further education).
        </p>
      </div>
    </div>
  );
};

export default TeachingWise;
