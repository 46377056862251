import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";




const SessionWise = () => {
  return (

    <div className="fill-page">
      <TopComp />

      <div className="flex flex-col flex-item-center flex-space-around gap-half">

        <p className="h1">SessionWise</p>

        <p className="h2">Training communities to cure each other's mental traumas</p>


{/*       <div id="divSlider" class="dim_at_back">
          <img id="imgSlider" alt="" />
      </div>

 */}

        <p className="h3">We do two things:</p>

        <p>1. We train communities that have little or no access to mental health services how to do peer counselling.  We want to help them eliminate traumatic memories, panic attacks, anxiety and depression.  We particularly are interested in addressing the mental trauma of Gender-Based-Violence and other crime-related trauma, as well as Covid-related bereavements.  We do effective mental health first-aid.</p>
        <p>2. We give one-to-one sessions to private clients to cure them of their trauma, depression and anxiety.</p>
        <p>Most of #2 is paid.  Most of #1 is pro bono.  #2 pays for #1.</p>
 


        <p className="h2">Example of community training</p>

        <img className="width9030" src={require("../images/sessionwise/punjabi_dancers_big.jpg")} alt="" />

        <p>Priya Sharma lives in a small town in India. She was traumatised by her husband, who thankfully has now left her. Her friend Lakshmi had years of abusive criticism from her mother-in-law, and now has depression. Her friend Anika gets panic attacks. Their friend Jyoti has anxiety. They get together for two weekends to take an online course, run by our experts.</p>

        <div><p>In the course, they learn:</p></div> 
        
        <ul>
          <li>What causes trauma, depression, anxiety and panic attacks. They learn the straightforward techniques that can be used to eradicate these conditions</li><li>Special communication skills that will help them do the techniques with each other.</li><li>They do lots of role plays of counselling sessions for practice. </li><li>Then they start to give each other real peer counselling sessions using the techniques that they have learnt on the course.</li></ul>
        
        <p>Within two months, Priya, Lakshmi, Anika and Jyoti have all made life-changing improvements in their mental health. </p>
        
        <p className="text-left">Here we explain how this works.</p>


        <p className="h2">Peer Counselling works very well</p>

        <img className="width9030" src={require("../images/sessionwise/twoShots5_big.jpg")} alt="" />

        <p>Our experts have spent many years delivering effective therapy to those in need, but now they have come to realise that there is no reason their clients cannot deliver these services to each other. It really isn't rocket science!</p><p>This approach solves the problem of cost and the insurmountable problem that there will never be enough formally trained clinical psychologists to deal with even a fraction of the world's mental health problems. </p><p>Not all conditions are amenable to this approach, but trauma memories and panic attacks in particular are very amenable to peer counselling. </p><p> We train our students to carry out full assessments before and after giving sessions to those in need in their communities, so everyone has a more objective view of the improvements that have been made. </p>

        <p className="h2">Where these techniques come from</p>

        <img className="width9030" src={require("../images/sessionwise/tear_big.jpg")} alt="" />

        <p>Around the beginning of the 20th century, Sigmund Freud changed the course of psychology by suggesting that our unconscious is responsible for the condition of our mental health. Then after World War 1, huge numbers of soldiers came back with a debilitating and chronic set of mental conditions which were called 'Shell Shock' or 'Battle Fatigue'. We now call this PTSD - Post Traumatic Stress Disorder.</p>
        
        <p>During the Vietnam War, a huge number of American soldiers were coming back to civilian life in no fit shape to function in it, and committing suicide in frightening numbers. In the early 80s, a group of psychologists and psychiatrists in California (and England) put together a technique for alleviating PTSD. It is called TIR - Traumatic Incident Reduction. It worked in nearly all cases, which is something you can't say about most therapeutic techniques. </p>
        
        <p>This technique has since been used all over the world, for war victims, torture victims, victims of terrorist attacks (including 9/11 first responders, London Metropolitan police first repsonders and Afghanistan mass shooting survivors), firemen, paramedics, rape victims, bereavements, accidents and a whole gamut of other traumatic incidents. </p>
        
        <p>We have adapted this technique so that community groups in developing countries and other places with no effective mental health services can be trained to give each other sessions to alleviate PTSD and panic attacks, as well as depression and anxiety.</p>
        
        <p>ReadingWise International uses this and related techniques to train communities in Asia and Africa and elsewhere. Our founders have a long track record of working in the field of womens empowerment projects, especially literacy and employability, and GBV (Gender Based Violence).</p>

        <p className="h2">What TR (Trauma Resolution) does and how it works</p>

        <img className="width9030" src={require("../images/sessionwise/twoShots16_big.jpg")} alt="" />

        <p>TR (Trauma Resolution) is a one-to-one method of educating people to reduce traumatic stress from emotionally and/or physically painful events in the past. It involves re-experiencing past traumas in a completely safe environment, free of distractions, judgments, or interpretations. </p>

        <p> The technique is deceptively simple. The Client locates a traumatic incident in their mind, and they address the incident like a video in their head. They watch the video, and then tell the Coach (therapist) what happened. After repeating this multiple times, the emotional charge dissipates, as the Client recovers memories that were previously hidden. Sometimes there will be an earlier incident that was similar and also needs attention in order to achieve full resolution. </p>

        <p>Typically addressing the main traumatic incidents in someone's life will take less than 20 hours of session time, and often far less.</p>
  
        <p className="h2">Other techniques</p>

        <img className="width9030" src={require("../images/sessionwise/twoShots4_big.jpg")} alt="" />

        <p>We also have lighter techniques that help people who are not yet ready to confront repressed memories.  We also have techniques to address chronic situations rather than incidents, such as abusive relationships.</p>

        <p className="h2">What happens during a session?</p>

        <img className="width9030" src={require("../images/sessionwise/twoShots6_big.jpg")} alt="" />

        <p>Trauma Resolution sessions are one-to-one. The practitioner is called a Coach. Sessions do not have a fixed time; they last until the Coach has finished the topics he/she intended to address that day, and only if the Client is also happy that those topics are complete. </p>
        <p> Here are some examples of topics: </p>
        
        <ul>
          <li>Traumatic incidents in the past</li>
          <li> Potential traumatic incidents in the future </li>
          <li> Subjects which are difficult to talk about</li>
          <li>People who are difficult to deal with </li>
          <li> Unresolved problems </li>
          <li> Major upsets </li>
          <li> Anxieties </li>
          <li> Traumatic change </li>
          <li> Criticism </li>
          <li>Unwanted and/or uncontrolled emotions </li>
        </ul> 
        
        
        <p> Whilst the Coach advises the Client what to do, they will never tell the Client what to think or interpret information for them, and this is key to the techniques that we use. The Coach will always allow the Client to achieve their own insights, and provide them with the tools to do so. </p>
  


 
        <p className="h2">Our Training Sessions</p>
        <p>
            The learning objective of SessionWise Training is to enable trainees to confidently work with traumatised people in their community in order to cure them of their traumatic memories. &nbsp;The program consists of the following:
        </p>
        
        <ol>
            <li>
                Access to this app, which contains an online self-paced theory section, a practice role plays section, and a comprehensive reference section containing our techniques.  The theory section takes a couple hours or so to do.
            </li>
            <li>Two 4-hour Zoom (or WhatsApp or Facebook Messenger) classes, where trainees do intensive exercises and role plays to learn how to do peer counselling.  We usually do these at weekends.  Contact us to find out the next training in your time zone.
            </li>
            <li>Trainees then pair off to give each other practice sessions followed by real sessions.  These are supervised by SessionWise instructors.
            </li>
            <li>Trainees should now be able to give sessions to anyone in their community.  They can utilise SessionWise instructors for consulting on case planning or any other aspect of the counselling that they are given.</li>
        </ol>

      </div>  


      
       
    </div>

    
  );
};

export default SessionWise;
