import React from "react";

import "../css/rw.css";
import HomeCardComp from "../components/HomeCardComp";
import TopComp from "../components/TopComp";

const Home = () => {
  return (
    <div className="fill-page">
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around  gap-half">
        <p className="home-para1">
          Over 600,000 people have now had their lives changed by ReadingWise
          life skills courses. <br />
          We have enabled hundreds of thousands of adults and children to read.<br/>         
          We have enabled tens of thousands of unemployed youths to become employed.<br/>
          Now we train community workers to fix PTSD, panic attacks and other mental
          health problems in places where effective mental health treatment does
          not exist or is unaffordable. <br />
          Our training is fast and effective and affordable. 
        </p>

        <div className="flex flex-direction flex-item-center flex-space-around flex-wrap gap1 margin2">
   

          <HomeCardComp
            title="The Trauma Solution Training"
            image={require("../images/home/twoShots5.jpg")}
            text="This training is a 16 hour online course to train community workers on how to resolve traumas with their friends, family, colleagues and anyone who suffers from traumatic stress. Students learn theory from our app and try out the techniques, and then demoonstrate their skills to our experts before using them on their fellow students and the rest of their commmunity."
            navigateTo="https://traumasol.org"
          />

          <HomeCardComp
            title="Tara Akshar Literacy"
            image={require("../images/home/bhatti_poem_300.jpg")}
            text="Our TARA Akshar+ Hindi literacy program has enabled 238,000 illiterate women from rural India to learn to read and write Hindi (and count) in less than two months with minimal drop-outs.  Above is a graduate who stood up at a graduation ceremony to READ a poem that she had just WRITTEN."
            navigateTo="https://taraakshar.org"
          />

          <HomeCardComp
            title="Employability Life Skills"
            image={require("../images/home/emplw.jpg")}
            text="Our Employability Life Skills programs teach how to communicate, how to train others, how to learn quickly and lots more. 45,000 unemployed youths in below-poverty-line areas of India have graduated these courses AND went on to get jobs or higher education."
            navigateTo="/Employability"
          />

          <HomeCardComp
            title="ConfidenceWise"
            image={require("../images/home/confw_anjana.jpg")}
            text="Our ConfidenceWise is a series of accelearated meditation techniques and role plays that can eliminate shyness and fear of making mistakes. It is for classroom use only, but the instructions are online."
            navigateTo="/ConfidenceWise"
          />

          <HomeCardComp
            title="TeachingWise"
            image={require("../images/home/tw_mindmap.jpg")}
            text="TeachingWise retrains teachers in accelerated learning techniques and classroom strategies to revolutionise school learning. No more chalk and talk. Lots more peer learning, mindmaps, memory techniques, self-paced learning and inspiring lesson plans. No more children at the back of class being bypassed in the learning process."
            navigateTo="/TeachingWise"
          />
        </div>

        <p className="text-center">
          Copyright © 2024 ReadingWise International
        </p>
      </div>
    </div>
  );
};

export default Home;
