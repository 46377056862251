import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";

const PressHindi = () => {
  return (
    <div
      className="fill-page"
      style={{ background: "linear-gradient(#def, #fed)" }}
    >
      <TopComp />

      <div className="flex flex-col flex-item-center flex-space-around gap-half width100">
        <p className="h1">Press in Hindi</p>

        <h3>
          Here is a sample of the 500 + articles that have been written about
          our programs.
        </h3>

        <img
          src={require("../images/media/HindiPress.jpg")}
          alt="Hindi press"
        />
      </div>
    </div>
  );
};

export default PressHindi;
