import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/rw.css";

const HomeCardComp = ({ title, image, text, navigateTo }) => {
  const navigate = useNavigate();

  return (
    <div className="home-card">
      <p className="home-card-title">{title}</p>
      <img src={image} className="home-card-image" alt="" />
      <p className="home-card-text">{text}</p>

      {navigateTo.startsWith("/") ? (
        <button
          className="home-card-btn border-thin border-round"
          onClick={() => navigate(navigateTo)}
        >
          More...
        </button>
      ) : (
        <a
          href={navigateTo}
          rel="noopener noreferrer"
          className="home-card-btn border-thin border-round dont-underline"
        >
          Go to website...
        </a>
      )}
    </div>
  );
};

export default HomeCardComp;
