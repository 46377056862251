import React from "react";
import ReactPlayer from "react-player/youtube";

import "../css/rw.css";


const VideoComp = ({ url }) => {
  return (
    <div className="margin-vertical-minus4">
      <ReactPlayer url={url} controls width="370px" />
    </div>
  );
};

export default VideoComp;
