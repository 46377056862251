import React from "react";
import "../css/rw.css";
import "../css/results.css";
import TopComp from "../components/TopComp";

const ayesha = require("../images/results/ayesha_blur.jpg");
const fatuma = require("../images/results/fatuma_blur.jpg");
const joan = require("../images/results/joan_blur.jpg");
const juana = require("../images/results/juana_blur.jpg");
const mariam = require("../images/results/mariam_blur.jpg");
const marie = require("../images/results/marie_blur.jpg");
const palma = require("../images/results/palma_blur.jpg");

const ayesha_graph = require("../images/results/ayesha_graph.jpg");
const fatuma_graph = require("../images/results/fatuma_graph.jpg");
const joan_graph = require("../images/results/joan_graph.jpg");
const juana_graph = require("../images/results/juana_graph.jpg");
const mariam_graph = require("../images/results/mariam_graph.jpg");
const marie_graph = require("../images/results/marie_graph.jpg");
const palma_graph = require("../images/results/palma_graph.jpg");

const Results = () => {
  return (
    <div className="fill-page bg-gainsboro">
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-between bg-white">
        <p className="h1">Results</p>

        <div className="page-text">
          <p>
            On this page, you will read about 7 women from around the world who
            came to us online to learn how to get rid of their PTSD and
            depression.
            <br />
            In the beginning, 6 out of 7 had PTSD, and 6 out of 7 had clinical
            depression.
            <br />
            They each received an average of 6 trauma resolution sessions each.
            <br />
            <i>
              Afterwards, NONE OF THEM HAD PTSD, AND ALL BUT ONE HAD NO
              DEPRESSION.
            </i>
            <br />
            (The seventh still has mild depression but we're working on it!)
          </p>

          <p>
            All clients and students are given a set of standard psychometric
            tests - before and after sessions - to assess their levels of
            trauma/PTSD, clinical depression and anxiety.
          </p>
          <p>
            Some just received sessions, some gave and received sessions to each
            other after a short training program.
          </p>
          <p>Only one of the 7 is a mental health professional.</p>

          <p>
            The names and images have been changed obviously for reasons of
            confidentiality.
          </p>

          <p>
            IF YOU WOULD LIKE TO GET RID OF YOUR PTSD OR DEPRESSION, OR WANT TO
            LEARN HOW YOU CAN GET RESULTS LIKE THIS, PLEASE CONTACT US.
            <br />
            My email address is victor.lyons@readingwiseint.com
            &nbsp;&nbsp;&nbsp;&nbsp;My Whatsapp number is +34 684 301727.
          </p>
          <p>Now please read on...</p>
        </div>

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={ayesha} alt="" />
          <img className="results-graphic" src={ayesha_graph} alt="" />

          <div className="margin-left5R">
            <p>
              <strong>Ayesha</strong> is a lady in her thirties who lives in
              Canada. She grew up in a severely dysfunctional family. She has a
              long history of mental ill-health. She has had many years of
              psychotherapy and drug treatments, with stays in mental hospitals.
            </p>
            <p>
              When she came to us, she had PTSD, severe depression, and anxiety.{" "}
            </p>
            <p>We gave her FOUR sessions of approximately one hour each.</p>
            <p>
              She now has no PTSD, only mild depression, and tested negative for
              anxiety.
            </p>
          </div>
        </div>

        <br />

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={juana} alt="" />
          <img className="results-graphic" src={juana_graph} alt="" />
          <div className="margin-left5R">
            <p>
              <b>Juana</b> is a lady in her twenties who lives in Mexico.
            </p>
            <p>
              Her best friend died, tragically young, from a slow cruel illness.
            </p>
            <p>When she came to us, she had severe PTSD. </p>
            <p>We gave her FOUR sessions of approximately one hour each.</p>
            <p>
              She now tests negative for PTSD <i>and</i> clinical depression{" "}
              <i>and</i> anxiety.
            </p>
          </div>
        </div>

        <br />

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={fatuma} alt="" />
          <img className="results-graphic" src={fatuma_graph} alt="" />

          <div className="margin-left5R">
            <p>
              <b>Fatuma</b> is lady in her forties who lives in Kenya.
            </p>
            <p>
              She is a survivor of sex trafficking. She was taken to another
              country and eventually managed to escape. Along the way, she
              caught HIV, typhoid and tuberculosis. She is still HIV positive
              and has chronic health issues.
            </p>
            <p>
              She did the SessionWise training with her friend Palma (see next),
              also a sex trafficking survivor, and they gave each other 8
              sessions. Each session took less than an hour.
            </p>
            <p>
              Fatuma's trauma/distress level went from severe (PTSD) to mild (no
              PTSD).
            </p>
            <p>
              Her depression level went from moderate depression to minimal.
            </p>
            <p>Her anxiety level went from mild anxiety to NONE.</p>
          </div>
        </div>

        <br />

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={palma} alt="" />
          <img className="results-grraphic" src={palma_graph} alt="" />
          <div className="margin-left5R">
            <p>
              <b>Palma</b> is lady in her thirties who lives in Kenya.
            </p>
            <p>
              She was trafficked four years ago to a country 9,000 km away, but
              eventually managed to escape.
            </p>
            <p>
              She did the SessionWise training with her friend Fatuma (see
              previous), also a sex trafficking survivor, and they gave each
              other 8 sessions. Each session took less than an hour.
            </p>
            <p>
              Palma's trauma/distress level went from moderate distress to NONE.
            </p>
            <p>Her depression level went from moderately severe to minimal.</p>
            <p>Her anxiety level went from moderate to none.</p>
          </div>
        </div>

        <br />

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={joan} alt="" />
          <img className="results-grraphic" src={joan_graph} alt="" />

          <div className="margin-left5R">
            <p>
              <b>Joan</b> is lady in her forties who lives in South Africa.
            </p>
            <p>
              She was a businesswoman, wife and mother who inadvertently got
              targetted by a criminal gang. She had to flee her house and her
              business, and resettle on the other side of the country.
            </p>
            <p>
              She did the SessionWise training online with her study partner
              Marie (see next).
            </p>
            <p>She received THREE sessions (average of one hour each).</p>
            <p>
              Her trauma/distress level went from severe (PTSD) to NONE (no
              PTSD).
            </p>
            <p>Her depression level went from moderate to minimal.</p>
            <p>Her anxiety level went from mild to NONE.</p>
          </div>
        </div>

        <br />

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={marie} alt="" />
          <img className="results-grraphic" src={marie_graph} alt="" />

          <div className="margin-left5R">
            <p>
              <b>Marie</b> is lady in her forties who lives in Cameroon.
            </p>
            <p>
              She was raped when she was young. She never recovered from the
              death of her mother nearly thirty years ago.{" "}
            </p>
            <p>
              She did the SessionWise training online with her study partner
              Joan (see previous).
            </p>
            <p>She received 4 sessions from Joan.</p>
            <p>
              Marie's distress level went from severe (PTSD) to mild (no PTSD).
            </p>
            <p>Her depression level went from moderate to minimal.</p>
            <p>Her anxiety level went from severe to mild.</p>
          </div>
        </div>
        <br />

        <div className="flex flex-row flex-item-center gap1 flex-wrap border-thin padding1 margin2 bg-white">
          <img className="results-image" src={mariam} alt="" />
          <img className="results-grraphic" src={mariam_graph} alt="" />

          <div className="margin-left5R">
            <p>
              <b>Mariam</b> is lady in her thirties who lives in Canada.
            </p>
            <p>
              When she came to us ealier this year, she was suffering from 10
              years of physical and mental abuse from her (now ex) husband.
            </p>
            <p>We then gave her ELEVEN sessions (average of one hour each).</p>
            <p>
              Her distress/trauma level went from severe (PTSD) to mild (no
              PTSD).
            </p>
            <p>Her depression level went from severe depression to ZERO.</p>
            <p>Her anxiety level went from severe anxiety to ZERO.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
