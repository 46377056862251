import React, { useState } from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";

const PressEnglish = () => {
  const [rediffLarger, setRediffLarger] = useState(false);
  const [wiseLarger, setWiseLarger] = useState(false);
  const [gulfLarger, setGulfLarger] = useState(false);
  const [independentLarger, setIndependentLarger] = useState(false);
  const [dawnLarger, setDawnLarger] = useState(false);

  const handleRediff = () => {
    if (rediffLarger === false) {
      setRediffLarger(true);
    } else {
      setRediffLarger(false);
    }
  };
  const handleWise = () => {
    if (wiseLarger === false) {
      setRediffLarger(true);
    } else {
      setWiseLarger(false);
    }
  };
  const handleGulf = () => {
    if (gulfLarger === false) {
      setGulfLarger(true);
    } else {
      setGulfLarger(false);
    }
  };
  const handleIndependent = () => {
    if (independentLarger === false) {
      setIndependentLarger(true);
    } else {
      setIndependentLarger(false);
    }
  };

  const handleDawn = () => {
    if (dawnLarger === false) {
      setDawnLarger(true);
    } else {
      setDawnLarger(false);
    }
  };

  return (
    <div className="fill-page bg-ivory">
      <TopComp />

      <p className="h1">Press in English</p>

      <p className="page-text">Click an image to expand it.</p>

      <br />

      <div
        id="divMaster"
        className="flex flex-row flex-wrap flex-space-around gap2"
      >

        <div className="border-thin bg-white padding1">
          {rediffLarger === false ? (
            <img
              src={require("../images/media/skillOutlook_wise/taraAkshar_skillOutlook_Wise-1.jpg")}
              className="width9050"
              alt=""
              onClick={handleWise}
            />
          ) : (
            <div className="width100">
              <iframe
                src="https://readingwiseint.org/PRESSLARGE/Wise.pdf"
                type="application/pdf"
                width="100%"
                height="500px"
                title="Wise article"
              >
                This browser does not support PDFs. Please download the PDF to
                view it.
              </iframe>
            </div>
          )}
        </div>

        <div className="border-thin bg-white padding1">
          {gulfLarger === false ? (
            <img
              src={require("../images/media/gulf_times/gulf-01.jpg")}
              className="width9050"
              alt=""
              onClick={handleGulf}
            />
          ) : (
            <div className="width100">
              <iframe
                src="https://readingwiseint.org/PRESSLARGE/gulf.pdf"
                type="application/pdf"
                width="100%"
                height="500px"
                title="Gulf Times article"
              >
                This browser does not support PDFs. Please download the PDF to
                view it.
              </iframe>
            </div>
          )}
        </div>

        <div className="border-thin bg-white padding1">
          {independentLarger === false ? (
            <img
              src={require("../images/media/independent/independent_vel.jpg")}
              className="width9050"
              alt=""
              onClick={handleIndependent}
            />
          ) : (
            <div className="width100">
              <iframe
                src="https://readingwiseint.org/PRESSLARGE/independent.pdf"
                type="application/pdf"
                width="100%"
                height="500px"
                title="Independent article"
              >
                This browser does not support PDFs. Please download the PDF to
                view it.
              </iframe>
            </div>
          )}
        </div>



        <div className="border-thin bg-white padding1">
          {rediffLarger === false ? (
            <img
              src={require("../images/media/rediff/ta_rediff-1.jpg")}
              className="width9050"
              alt=""
              onClick={handleRediff}
            />
          ) : (
            <div className="width100">
              <iframe
                src="https://readingwiseint.org/PRESSLARGE/rediff.pdf"
                type="application/pdf"
                width="100%"
                height="500px"
                title="Rediff article"
              >
                This browser does not support PDFs. Please download the PDF to
                view it.
              </iframe>
            </div>
          )}
        </div>

        <div className="border-thin bg-white padding1">
          {dawnLarger === false ? (
            <img
              src={require("../images/media/dawn/dawn_feroze_web.jpg")}
              className="width9050"
              alt=""
              onClick={handleDawn}
            />
          ) : (
            <div className="width100">
              <img
                src={require("../images/media/dawn/dawn_feroze_large.jpg")}
                width="100%"
                alt="Dawn article"
              />

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PressEnglish;
