import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";
import VideoComp from "../components/VideoComp";

const Videos = () => {
  return (
    <div
      className="fill-page width100"
      
    >
      <TopComp />

      <div className="flex flex-col flex-item-center flex-space-between gap2">
        <p className="h1">Videos</p>

        <div className="bg-white border-round padding1 font-larger">
          <p><b>
            Training for Trafficked Survivors
            <br />
            Conversation between Emma Lees of Liluye NGO and Victor Lyons
            </b></p>
          <VideoComp url="https://www.youtube.com/watch?v=gkQPhwjwR7g" />
        </div>

        <div className="bg-white border-round padding1">
          <p class="h3">
            4 minute video about Tara Akshar Hindi Literacy Program by WISE
            <br />
            (World Innovation Summit for Education)
          </p>
          <VideoComp url="https://www.youtube.com/watch?v=jldwXwZec84" />
        </div>

        <div className="bg-white border-round padding1">
          <p class="h3">
            Interview with Victor Lyons by TV presenter Konnie Huq about Tara
            Akshar Literacy Program
          </p>
          <VideoComp url="https://www.youtube.com/watch?v=lAOW1HUD4Og" />
        </div>
      </div>
    </div>
  );
};

export default Videos;
