import "../css/rw.css";
import TopComp from "../components/TopComp";

const photoRegina = require("../images/people/regina.jpg");
const photoLM = require("../images/people/lm.jpg");
const photoMJ = require("../images/people/mjblur.jpg");
const photoKL = require("../images/people/klblur.webp");

const Testimonials = () => {
  return (
    <div className="fill-page">
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around bg-white">
        <p className="h1">Testimonials (Mental Health)</p>

        <div className="border-thin margin2 padding1">
          <img className="width5015" src={photoLM} alt="" />
          <div>
            <p>
              Have you been frequently told that you are a medical anomaly? Have
              doctors said that they can't make their minds up about you? Have
              you been misdiagnosed with a ridiculous medical condition? If so,
              you should try checking out Victor Lyons. His skills as a trauma
              therapist will be able to help you. He's the best I have come
              across in understanding the interplay between the
              mind and the body. The work I have done with him has transformed
              the quality of my life for the better.
            </p>
            <br />
            <p>
              <i>LM, Cornwall, UK</i>
            </p>
          </div>
        </div>

        <div className="border-thin margin2 padding1">
          <img className="width5015" src={photoRegina} alt="" />
          <p>
            My name is Young Regina Afanwi. I live in Bamenda, in North-West
            Cameroon. I run a community organisation that helps disabled people.
          </p>
          <p>
            I have received several traumatic incident sessions recently. The
            first one concerned a visit to my husband, a whistleblower who has
            been in prison for 10 months without charge. After one particular
            visit, I became very depressed and my heart was broken. Later that
            day, I had a trauma counselling session. At the end of that session,
            I felt so happy and grateful that I had visited him, which was the
            complete opposite of what I felt before the session. I felt like a
            heavy load had been taken off my head. I was on top of my spirit
            that evening.
          </p>
          <p>
            In the next session, I addressed an earlier incident, when two of my
            children were kidnapped at gun-point. They were eventually returned
            to me, but had been badly beaten up. The session helped me recover
            from that trauma.
          </p>
          <p>
            I have now been trained with my study partner so I can give these
            sessions to other people. Now I am giving and receiving these
            sessions.
          </p>
          <p>
            And to Victor I say 'You are God sent, thank you so much for always
            being there and ever willing to train us and give sessions, You are
            the best!' I recommend these sessions and the training to all who
            are undergoing any form of trauma out there.
          </p>
        </div>

        <div className="border-thin margin2 padding1">
          <img className="width5015" src={photoMJ} alt="" />
          <p>
            The fundamental difference for me between CBT, and TIR which Victor
            uses, is that CBT is like having a massive gash on your leg that's
            stopping you living properly, and putting a big plaster on it and a
            nice bit of padding to stop the bleeding. But you'd have to change
            that padding every week for the rest of your life. It's work, and
            it's surface level treatment. On the other hand, TIR is going to the
            root of the problem and dealing with the emotional charge on a
            cellular level and an emotional level, so that you end up healing
            the leg and you just get on with your life.
          </p>
          <p>
            I have not seen CBT be that successful in healing trauma in pretty
            much anyone I know. TIR heals people. It heals their brain or psyche
            and they are able to function and actually react differently in life
            situations.
            <br />
            <i>MJ, London, UK</i>
          </p>
        </div>


        <div className="border-thin margin2 padding1">
          <img className="width5015" src={photoKL} alt="" />
          <p>
          I came with no expectations but with an awareness that self-understanding, wisdom and self-care were deeply needed. The journey through trauma, self-discovery and self-knowledge has been transformative not only for the 'shutter-up', lights on moments, but for the soulful, meditative calm it has brought. I could not recommend more highly to both those in acute need and those who come to realise later in the day that they have a chronic need. Life changing.
            <br />
            <i>KL, Sussex, UK</i>
          </p>
        </div>

      </div>
    </div>
  );
};

export default Testimonials;
