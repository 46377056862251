import React from "react";
import "../css/rw.css";
import parse from "html-react-parser";
import TopComp from "../components/TopComp";

const myContent = require("../jsonContent/trans_Contact.json");
const langNum = 0;

const Contact = () => {
  const { pageTitle, pageText, captionPhotoText, headingMap } =
    myContent.trans[langNum];

  return (
    <div
      className="fill-page"
      style={{
        background: "linear-gradient(#fcffef, #fcffff)",
      }}
    >
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-between gap1">
        <p className="h1">{pageTitle}</p>

        <div className="page-text">{parse(pageText)}</div>

        <p className="h2">{headingMap}</p>

        <img
          className="photo-full"
          src={require("../images/pictures/bahiadorada.jpg")}
          alt=""
        />
        <div>{parse(captionPhotoText)}</div>
      </div>
    </div>
  );
};

export default Contact;
