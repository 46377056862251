import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";

const ConfidenceWise = () => {
  return (
    <div
      className="fill-page"
      style={{ background: "linear-gradient(#def, #fed)" }}
    >
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around gap-half">
        <p className="h1">ConfidenceWise</p>
        <p className="h2">How to cure shyness</p>

        <img
          id="img1"
          src="../Images/anjana_sound.jpg"
          className="width9050"
          alt=""
        />

        <p>
          Many people in the developing world AND the developed world are too
          shy and too introverted, and do not have the confidence to change the
          world around them. Thus environments stay the same.
        </p>

        <p>This course is the solution. It teaches: </p>

        <ul>
          <li>How to face other people</li>
          <li>How to not be shy</li>
          <li>How to be extrovert</li>
          <li>How to control conversations</li>
          <li>How not to be put off by others</li>
          <li>How to handle criticism</li>
        </ul>

        <p>
          The course, which is more than 95% practical exercises, comprises a
          series of tough one-to-one communication drills and role-plays,
          together with public speaking exercises.
        </p>

        <p>
          It is suitable for everyone – children and adults, urban and rural,
          NGOs and corporates, schools and families.
        </p>

        <p>
          <b>Length: </b>2 to 5 days
        </p>
        <p>
          <b>Method: </b> Classroom with facilitator
        </p>
      </div>
    </div>
  );
};

export default ConfidenceWise;
