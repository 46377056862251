import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/rw.css";

const TopComp = () => {
  const navigate = useNavigate();

  const thisScreen = useLocation().pathname;

  return (
    <div className="width100 bg-white">
      <img
        className="header-logo"
        src={require("../images/logos/RWInternationalLogo500.jpg")}
        alt=""
      />

      <div className="flex flex-row flex-bunch gap-half flex-wrap  width100">
        {thisScreen !== "/" && (
          <button className="rwButton" onClick={() => navigate(-1)}>
            &lt; Back
          </button>
        )}

        {thisScreen !== "/" ? (
          <button
            type="button"
            className="rwButton"
            onClick={() => navigate("/")}
          >
            Home
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            Home
          </button>
        )}

        {thisScreen !== "/Results" ? (
          <button className="rwButton" onClick={() => navigate("/Results")}>
            Results
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            Results
          </button>
        )}

        {thisScreen !== "/Testimonials" ? (
          <button
            className="rwButton"
            onClick={() => navigate("/Testimonials")}
          >
            Testimonials
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            Testimonials
          </button>
        )}

        {thisScreen !== "/Contact" ? (
          <button className="rwButton" onClick={() => navigate("/Contact")}>
            Contact
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            Contact
          </button>
        )}

        {thisScreen !== "/Videos" ? (
          <button className="rwButton" onClick={() => navigate("/Videos")}>
            Videos
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            Videos
          </button>
        )}

        {thisScreen !== "/PressEnglish" ? (
          <button
            className="rwButton"
            onClick={() => navigate("/PressEnglish")}
          >
            English Press
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            English Press
          </button>
        )}

        {thisScreen !== "/PressHindi" ? (
          <button className="rwButton" onClick={() => navigate("/PressHindi")}>
            Hindi Press
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            Hindi Press
          </button>
        )}

        {thisScreen !== "/About" ? (
          <button className="rwButton" onClick={() => navigate("/About")}>
            About us
          </button>
        ) : (
          <button type="button" className="rwButton-disabled">
            About us
          </button>
        )}
      </div>
    </div>
  );
};

export default TopComp;
