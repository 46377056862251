import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";
import parse from "html-react-parser";



const TeachingWise = () => {
  return (
    <div className="fill-page">
      <TopComp />

      <p className="h1">The TeachingWise Course</p>
      
      <p className="h2">How to get bring teaching techniques out of 19th Century into the 21st Century</p>
      

      <img
        className="photo-full"
        src={require("../images/pictures/rowlatt_500.jpg")}
        alt=""
      />

      <div className="page-text"><p>Imagine if there was a set of techniques that enabled most of your pupils to learn stuff way faster than ever before, and retain much much better, all with minimal effort on your part.</p><p>Imagine if there was a completely different way of organising your classroom so pupils didn’t sit at the back of your class not participating. Instead they got enthusiastic and excited about your lessons and the subjects you teach.</p><p>The good news is that these techniques do exist. They are used in many independent schools around the world. They include mind maps, peer coaching and memory techniques. All of them have been fully tried and tested. Some of these techniques date back to the Greeks and Romans. We want to teach them to you, so you can use them in your classrooms. Many of these techniques are exactly the same as those used by competitors in the World Memory Championships.</p><p>And of course, we can show you how to make them work just as well in virtual classroom as a live classroom.</p><p>This is a course for you and your colleagues to transform the learning rate of your students. We have been using it around the world for years. We have trained adult trainers all over India in these techniques. We have trained school teachers in Andhra Pradesh and Telengana. The course is really short. You do a day’s theory online on your own, and then we do a day’s training with you on Zoom or Whatsapp.</p></div>
    </div>
  );
};

export default TeachingWise;
