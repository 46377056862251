import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import About from "./screens/About.js";
import PressEnglish from "./screens/PressEnglish.js";
import PressHindi from "./screens/PressHindi.js";
import Results from "./screens/Results.js";
import Testimonials from "./screens/Testimonials.js";
import CV from "./pdfs/VictorLyonsBioJan2023.pdf";
import SessionWise from "./screens/SessionWise.js";
import Videos from "./screens/Videos.js";
import TeachingWise from "./screens/TeachingWise.js";
import Employability from "./screens/Employability.js";
import ConfidenceWise from "./screens/ConfidenceWise.js";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Testimonials" element={<Testimonials />} />
          <Route path="/About" element={<About />} />
          <Route path="/PressEnglish" element={<PressEnglish />} />
          <Route path="/PressHindi" element={<PressHindi />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Results" element={<Results />} />
          <Route path="/CV" element={<CV />} />
          <Route path="/ConfidenceWise" element={<ConfidenceWise />} />
          <Route path="/SessionWise" element={<SessionWise />} />
          

          <Route path="/Videos" element={<Videos />} />
          <Route path="/TeachingWise" element={<TeachingWise />} />
          <Route path="/Employability" element={<Employability />} /> 
        </Routes>
      </Router>
    </div>
  );
};

export default App;
