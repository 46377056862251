import React from "react";
import "../css/rw.css";
import TopComp from "../components/TopComp";
import parse from "html-react-parser";

const myContent = require("../jsonContent/trans_About.json");
const langNum = 0;

const About = () => {
  const { pageTitle, textVel, textSaw, textEmma } = myContent.trans[langNum];

  return (
    <div
      className="fill-page"
      style={{ background: "linear-gradient(#def, #fed)" }}
    >
      <TopComp />

      <div className="flex flex-col flex-item-center flex-space-around gap-half width100">
        <p className="h1">{pageTitle}</p>

        <p className="h3">Victor Lyons CEO</p>

        <img
          className="photo-full"
          src={require("../images/people/velBW.jpg")}
          alt=""
        />
        <div className="page-text">{parse(textVel)}</div>

        <br/>

        <p className="h3">Sara Worsley COO</p>

        <img
          className="photo-full"
          src={require("../images/people/saraBig.jpg")}
          alt=""
        />
        <div className="page-text">{parse(textSaw)}</div>

        <br/>

        <p className="h3">Emma Lees<br/>Business Development</p>

        <img
          className="photo-full"
          src={require("../images/people/emmaLees.jpg")}
          alt=""
        />
        <div className="page-text">{parse(textEmma)}</div>
      </div>
    </div>
  );
};

export default About;
